import React from 'react';

const StoreProduct = (props) => {
    // const {date}=props.datas;
    return (
        <div>
            <h1>sghs</h1>
            <h1>{props.headline}</h1>
        </div>
    );
};

export default StoreProduct;