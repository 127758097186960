// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Img/success.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paymentSuccessContainer {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-color: black;\n    background-position: right bottom, left top;\n    background-repeat: no-repeat, repeat;\n    background-attachment: fixed;\n    padding: 15px;\n\n}\n\n.congratulation-card {\n    padding: 10px;\n    text-align: center;\n    height: 500px;\n    box-shadow: \"0px 14px 22px rgb(42 135 158 / 10%)\";\n    background-color: white;\n    border-top-right-radius: 50%;\n    border-bottom-left-radius: 30%;\n    display: flex;\n    justify-items: center;\n    \n\n}", "",{"version":3,"sources":["webpack://./src/Pages/Payment/SuccessOrder/SuccessOrder.css"],"names":[],"mappings":"AAAA;IACI,yDAA0C;IAC1C,uBAAuB;IACvB,2CAA2C;IAC3C,oCAAoC;IACpC,4BAA4B;IAC5B,aAAa;;AAEjB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,iDAAiD;IACjD,uBAAuB;IACvB,4BAA4B;IAC5B,8BAA8B;IAC9B,aAAa;IACb,qBAAqB;;;AAGzB","sourcesContent":[".paymentSuccessContainer {\n    background-image: url('./Img/success.gif');\n    background-color: black;\n    background-position: right bottom, left top;\n    background-repeat: no-repeat, repeat;\n    background-attachment: fixed;\n    padding: 15px;\n\n}\n\n.congratulation-card {\n    padding: 10px;\n    text-align: center;\n    height: 500px;\n    box-shadow: \"0px 14px 22px rgb(42 135 158 / 10%)\";\n    background-color: white;\n    border-top-right-radius: 50%;\n    border-bottom-left-radius: 30%;\n    display: flex;\n    justify-items: center;\n    \n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
